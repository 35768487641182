import React from 'react';
import styled from 'styled-components'
import { motion } from 'framer-motion';
import Img from 'gatsby-image';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

import SEO from '../components/utility/SEO'
import Fade from '../components/motion/Fade';
import Slide from '../components/motion/Slide';
import Reviews from '../components/home/Reviews'

import logo from '../assets/seasonal_logo_black_01.svg';

const PageWrapper = styled.div`
  &>*:nth-child(n+1) {
    padding: 5vh 5vw;
  }
`

const WelcomeWrapper = styled(BackgroundImage)`
  opacity: 1 !important;
  background-size: cover;
  background-color: rgba(26,26,26,0.65);
`;
const WelcomeContainer = styled.div`
  display: grid;
  position:relative;
  grid-auto-flow: row;
  color:var(--color-white);
  overflow-x:hidden;
  h1 {
    display:none;
    font-weight: bold;
    margin:1rem 0;
    text-align: center;
  }
  h3 {
    margin: 2rem 1rem 0;
    font-style: italic;
    text-align: center;
  }
  p {
    font-weight:400;
    text-align: center;
  }

  .first {
    background-color: var(--color-accent-yellow);
  }
  .last {
    color:white;
    background-color: var(--color-accent-blue);
  }
  .first:hover, .first:focus, .last:hover, .last:focus {
    background-color:transparent;
    color: var(--color-white);
  }
  .center {
    text-align: center;
  }

  @media only screen and (min-width: 800px) {
    grid-auto-flow: column;
    grid-template-columns: fit-content(500px);
    justify-content: center;
    align-items:center;
    min-height: 400px;

    .center {
      text-align: left;
    }
    & > *:nth-child(1) {
      grid-column: 2;
    }
    & > *:nth-child(2) {
      padding: 5rem 2rem;
      h1,
      h3,
      p {
        margin:1.75rem 0;
        text-align: left;
      }
      h1 {
        display:inherit;
        text-decoration: underline;
        letter-spacing: 2px;
      }
      h3 {
        margin: 10px 0;
      }
    }
  }

  @media only screen and (min-width: 1100px) {
    grid-template-columns: 550px 550px;
    & > *:nth-child(2) {
      h3 {
        margin-right: -4rem;
      }
      margin-left:10%;
    }
  }
`
const LogoImg = styled.img`
  display: block;
  margin: 0 auto;
  margin-top: 2.5vh;
  filter: drop-shadow(2px 4px 6px var(--color-black)) invert(1);

  @media only screen and (min-width: 800px) {
    height: 250px;
    margin-bottom:1rem;
  }
  z-index: 10;
`;

const ProgramsWrapper = styled.div`
  display: grid;
  justify-content: stretch;
  h2 {
    text-align: center;
    text-transform:uppercase;
    font-size:1.728rem;
    text-decoration:underline;
    letter-spacing:2px;
  }
  &>p {
    margin-bottom:.5rem;
    text-align: center;
    font-weight: normal;
    font-style: italic;
  }
`;
const ProgramContainer = styled(motion.div)`
  display:grid;
  grid-auto-flow:row;
  grid-template-columns: 50% 50%;

  .styled-icon {
    max-width:75px;
    margin:auto;
    margin:1rem auto;
  }
  .program-content {
    h3 {
      margin-top:0.5rem;
    }
    h3, p {
      text-align:center;
      padding:0 5px;
    }
    p {
      margin: 0.5rem 0;
    }
  }
  .icon-container {
    border-radius: 0 20px 0 0;
    background-color:var(--color-white-secondary);
    border-bottom:1px solid var(--color-light-black);
  }

  /* Each individual Program in the grid table */
  a {
    box-shadow:0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
    margin: 0.25rem;
    border: 1px solid #e0e0e0;
    border-radius: 0 20px;
    transition: var(--transition);
  }
  a:hover {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    color:inherit;
    transform:scale(1.04);
    transition: var(--transition);
  }

  @media only screen and (min-width: 800px) {
    grid-template-columns: repeat(3, minmax(200px,333px));
    grid-template-rows: auto auto;
    justify-self:center;
    border-radius: 2px 40px 20px 40px;
    a {
      margin:.75rem;
    }
    .program-content {
      h3 {
        margin-top:1rem;
      }
      p {
        margin:1rem 0;
      }
    }
  }
`

const BlogWrapper = styled(BackgroundImage)`
  /* Add a background image to the Welcome Banner */
  opacity: 1 !important;
  background-size: cover;
  background-color: rgba(26,26,26,0.75);
`;
const BlogContainer = styled.div`
  display: grid;
  position:relative;
  grid-auto-flow:column;
  grid-template-rows: auto auto;
  grid-template-columns: 85vw;
  justify-content: center;
  color:var(--color-white);

  div {
    margin:0.5rem;
  }


  h2 {
    text-align:center;
    text-transform: uppercase;
    text-decoration: underline;
    letter-spacing: 2px;
    font-size: 1.728rem;
    margin-bottom:0.75rem;
  }
  h3 {
    margin:0.5rem 0;
    letter-spacing:2px;
    font-style:italic;
    font-weight:300;
  }
  h4 {
    text-align:center;
  }
  div+div {
    text-align:center;
  }

  .post-background {
    border: 1px solid;
    border-radius:16px 0 16px 0;
    background-color:rgba(0,0,0,0.5);
    color:var(--color-white);
    padding:1rem;
  }

  .nutrition-button {
    color:var(--color-white);
    background-color:transparent;
    display:block;
    margin: 1rem auto;

  }
  .nutrition-button:hover, .nutrition-button:focus {
    color:var(--color-black);
    background-color:var(--color-white);
  }

  @media screen and (min-width: 800px) {
    grid-template-rows: auto;
    grid-template-columns: repeat(2, minmax(100px,500px));
  }
`
const BlogImageDiv = styled.div`
  margin: 0.75rem 5rem;
`

const ReviewsWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  h2 {
    font-size: 1.728rem;
    text-align: center;
    text-decoration:underline;
    text-transform:uppercase;
    letter-spacing:2px;
  }
  & > p:first-of-type {
    font-style: italic;
    text-align:center;
    font-weight:normal;
  }
`;

const Home = ({ data }) => {
  const { sanityHomepage, allSanityProgram, allSanityTestimonial, sanitySettings, allSanityPost } = data
  const reviews = allSanityTestimonial.nodes
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };
  return (
    <PageWrapper>
      <SEO description={sanityHomepage.description} keywords={sanitySettings.keywords} />
      <WelcomeWrapper fluid={sanityHomepage.mainBackgroundImage.asset.fluid}>
        <WelcomeContainer>
          <Slide
            isActive
            xDistance={0}
            yDirection={1}
            yDistance={50}
            duration={0.65}
            delay={0.25}
          >
            <LogoImg src={logo} height="200px" alt="CrossFit X-Factor" />
          </Slide>
          <Fade isActive>
            <h1>CrossFit X&#8209;Factor</h1>
            <h3>{sanityHomepage.brief}</h3>
            <p>{sanityHomepage.description}</p>
            <div className="center">
              <a href="https://crossfitxfactor.wodify.com/OnlineSalesPortal/AppointmentServices.aspx?LocationId=2499&OnlineMembershipId=140509" target="_blank">
                <button className="first">Free Intro</button>
              </a>
              <Link to="/about" >
                <button className="last">About X-Factor</button>
              </Link>
            </div>
          </Fade>
        </WelcomeContainer>
      </WelcomeWrapper>
      <ProgramsWrapper>
        <h2>Programs</h2>
        <p>Start Your Fitness Journey</p>
        <ProgramContainer
          variants={container}
          initial="hidden"
          animate="show"
          >
          {allSanityProgram.nodes.map((program, index) => (
            <Link key={`${program.slug.current}`} to={`/${program.slug.current}`}>
              <motion.div key={index} variants={item} style={{display:'grid'}}>
                <div className="icon-container">
                  <Img fluid={program.svgIcon.asset.fluid} alt={`${program.slug.current}-icon`} className="styled-icon" />
                </div>
                <div className="program-content">
                  <h3>{program.title}</h3>
                  <p>{program.description}</p>
                </div>
              </motion.div>
            </Link>
            ))}
        </ProgramContainer>
      </ProgramsWrapper>
      <BlogWrapper fluid={sanityHomepage.nutritionBackgroundImage.asset.fluid}>
        <BlogContainer>
          <div>
            <h2>Nutrition</h2>
            <p>{sanityHomepage.nutritionText}</p>
            <Link to="/nutrition"><button className="nutrition-button">Nutrition Program</button></Link>
          </div>
          <div>
            <h3>From our blog..</h3>
            <div className="post-background">
              <h4>{allSanityPost.nodes[0].title}</h4>
              <p>{allSanityPost.nodes[0].excerpt}</p>
            </div>
            <Link to="/blog"><button className="nutrition-button">Blog Articles</button></Link>
          </div>
        </BlogContainer>
      </BlogWrapper>
      <ReviewsWrapper>
        <h2>Reviews</h2>
        <p>Every Member With A Story</p>
        <Reviews reviews={reviews} />
      </ReviewsWrapper>
    </PageWrapper>
  );
};
export default Home;

export const query = graphql`
  query HomeQuery {
    sanitySettings {
      keywords
    }
    sanityHomepage {
      title
      description
      brief
      nutritionText
      mainBackgroundImage {
        asset {
          fluid(maxWidth:1920) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      nutritionBackgroundImage {
        asset {
          fluid(maxWidth:1920) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      nutritionImage{
        asset{
          fluid(maxHeight: 600){
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    allSanityProgram(sort: {fields: programOrder}, filter: {programCategory: {eq: "fitness"}}) {
      nodes {
        title
        description
        slug {
          current
        }
        svgIcon {
          asset {
            fluid(maxWidth:500) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _id
      }
    }
    allSanityTestimonial(sort: {fields: _createdAt, order: DESC}) {
      nodes {
        name
        image {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        description
        program {
          title
        }
      }
    }

    allSanityPost(limit: 1) {
      nodes {
        excerpt
        title
        mainImage {
          asset {
            fluid(maxWidth:400) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
