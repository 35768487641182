import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import Img from 'gatsby-image'
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa'
import { wrap } from "@popmotion/popcorn"

const Wrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  justify-self: center;
  max-width: 1000px;
  overflow: hidden;
  width:100%;

  .review-details {
    position:relative;
    display:grid;
    text-align:left;
  }
  .review-details p {
    max-height:300px;
    overflow:scroll;
  }
  .review-details::before {
    content: "";
    position: absolute;
    background-color: var(--color-white);
    z-index: -10;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 200%;
    transform: translate(-50%,-50%);
  }
  .description {
    color: var(--color-gray);
    font-style:italic;
    font-size:1.2rem;
    font-weight:400;
  }
  .description::before {
    content: '"';
  }
  .description::after {
    content: '"';
  }
  .program span {
    font-weight:bold;
  }
  .arrows {
    grid-row:1;
    margin-top:1rem;
    text-align:center;
    p {
      display:inline-block;
      margin: 0.25rem 2rem 0 2rem;
      color: var(--color-black);
      cursor:pointer;
      background-color: var(--color-white);
      font-size:2rem;
      transition: var(--transition);
    }
    p:hover, p:focus {
      color: var(--color-accent-blue);
      transition: var(--transition);
    }
  }
  @media only screen and (min-width: 600px) {
    max-height:500px;
    .review-details {
      padding: 1rem 0;
      grid-column:1;

      background-color:var(--color-white);
      z-index:500;
      padding-right: 0.75rem;
      margin-right: 0.75rem;
    }
    .arrows {
      grid-row:4;
      margin-top:0;
      text-align:left;
      margin: 0.25rem 1rem 0 1rem;
    }
    grid-auto-flow:column;
    grid-template-columns: 50% 50%;
  }
  @media only screen and (min-width: 800px) {
    .review-details {
      align-self:center;
    }
    .description, .program, h4 {
      text-align:left;
    }
    grid-template-columns: 40% 40%;
    grid-template-rows: auto;
    grid-auto-rows:0;
  }
  @media only screen and (min-width: 1000px) {
    grid-template-columns: 30% 70%;
  }
`
const Container = styled(motion.div)`
  display:grid;
  grid-auto-flow:column;
  width:100%;

  z-index:300;
`
const Track = styled(motion.div)`
  display: grid;
  grid-auto-flow:column;
  grid-template-columns: minmax(250px,300px);
  grid-auto-columns: minmax(250px, 300px);
  justify-self:start;
  height:350px;
  @media only screen and (min-width:600px) {
    height:inherit;
  }
`
const ImageWrapper = styled(motion.div)`
  position:relative;
  &::before {
    content:"";
    position: absolute;
    background-color: transparent;
    z-index: 10;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
  }
  align-self:center;
  &.secondary {
    cursor:pointer;
  }
  & .gatsby-image-wrapper {
    border-radius:8px;
    transition: 0.35s ease-out all;
  }
  &.secondary .gatsby-image-wrapper {
    filter: blur(4px);
    margin: 0 1.5rem;
    transition: 0.35s ease all;
  }
`
const Reviews = ({ reviews }) => {
  const detailControls = useAnimation()
  useEffect(() => {
    detailControls.start({
      y:[-100, 0],
      opacity:[0,1],
      transition: {
        type:"spring",
        duration: 0.35
      }
    })
  })

  const [reviewIndex, setReview] = useState(0)
  const incrementImages = (change, index) => {
    setReview(index || reviewIndex + change)
  }
  const activeReview = wrap(0, reviews.length, reviewIndex)
  const calculate = multiplier => {
    let offset = -250 * multiplier

    let position = `calc(${offset}px)`
    return position
  }

  return (
    <Wrapper>
      <Container
        drag="x"
        dragConstraints={{left:0,right:0}}
        onDragEnd={(e, { offset, velocity }) => {
          if (offset.x > 125) {
            incrementImages(-1);
          } else if (offset.x < -125) {
            incrementImages(1);
          }
        }}
      >
        <Track
          animate={{x: calculate(activeReview), transition: { delay: 0.15, duration: 0.5}}}

        >
          {reviews.map((review, index) => (
            <ImageWrapper
              key={`review-${index}`}
              className={`image ` + (activeReview == index ? "active" : "secondary")}
              onClick={() => {
                if(activeReview != index) {
                  incrementImages(0, index)
                }
              }}
            >
              <Img fluid={review.image.asset.fluid} />
            </ImageWrapper>
          ))}
          <ImageWrapper
              key={`review-0-copy`}
              className={`image secondary`}
              onClick={() => {
                incrementImages(1, 0)
              }}
            >
              <Img fluid={reviews[0].image.asset.fluid} />
            </ImageWrapper>
          <ImageWrapper
              key={`review-1-copy`}
              className={`image secondary`}
              onClick={() => {
                incrementImages(1, 1)
              }}
            >
              <Img fluid={reviews[1].image.asset.fluid} />
            </ImageWrapper>
        </Track>
      </Container>
      <div className="review-details">
        <motion.p animate={detailControls} className="description">{reviews[activeReview].description}</motion.p>
        <motion.h4 animate={detailControls}>{reviews[activeReview].name}</motion.h4>
        <motion.p animate={detailControls} className="program">
          <span>Program: </span>
          {reviews[activeReview].program.map((program, index) => (`${program.title}${index!=0 ? ', ': ''}`))}
        </motion.p>
        <div className="arrows">
          <motion.p role={'button'} whileTap={{scale: 0.9}}>
            <FaArrowCircleLeft onClick={() => incrementImages(-1)} />
          </motion.p>
          <motion.p role={'button'} whileTap={{scale: 0.9}}>
            <FaArrowCircleRight onClick={() => incrementImages(1)}/>
          </motion.p>
        </div>
      </div>
    </Wrapper>
  )
}

export default Reviews
