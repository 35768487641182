import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function Fade({
  children,
  isActive,
  duration = 0.3,
  delay = 0,
}) {
  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration, delay } }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
